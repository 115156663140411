/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../button'
import Card from '../card'
import CenterCol from '../center-col'
import Quote from '../quote'
import Section from '../section'

import { renderHTML, formatImage, ratioForImage } from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty, HStack } from '../../styles/els'

const Awards = (props) => {
  const { awards, color, secondary, ...otherProps } = props

  return awards && awards.length > 0
    ? awards.map((_award, _i) => {
        const { award, year, detail, label } = _award
        return (
          <React.Fragment key={_i}>
            <HStack
              key={_i}
              sx={{
                variant: secondary ? 'text.regular_25' : 'text.regular_36',
                alignItems: 'flex-end',
                display: ['block', null, null, null, 'flex'],
              }}
              space={5}
            >
              {year ? (
                <div
                  sx={{
                    color: 'grey1',
                    minWidth: secondary ? '50px' : '100px',
                  }}
                >
                  {year}
                </div>
              ) : null}
              {award ? <div>{award}</div> : null}
              {detail ? (
                <div sx={{ variant: 'text.regular_16', pb: '3px' }}>
                  {detail}
                </div>
              ) : null}
            </HStack>
            {label ? (
              <div sx={{ color: color ? color : null }}>{label}</div>
            ) : null}
          </React.Fragment>
        )
      })
    : null
}

export default Awards
