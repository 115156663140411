/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../../center-col'
import Card from '../../card'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const StatList = ({ statistics, pageColor }) => {
  return statistics && statistics.length > 0
    ? statistics.map((_stat, _i) => {
        const { value, label } = _stat
        return (
          <React.Fragment key={_i}>
            <div
              sx={{
                display: ['block', 'flex'],
                flexDirection: 'row',
                color: pageColor,
              }}
            >
              {value ? (
                <div
                  sx={{
                    variant: 'text.heading_108',
                    mt: '-12px',
                    width: ['100%', '50%'],
                  }}
                >
                  {value}
                </div>
              ) : null}
              {label ? (
                <div
                  sx={{ variant: 'text.regular_25', width: ['100%', '50%'] }}
                >
                  {label}
                </div>
              ) : null}
            </div>
            {_i < statistics.length - 1 ? (
              <div
                sx={{
                  pb: 3,
                  mb: 2,
                  borderBottomWidth: '1px',
                  borderBottomColor: 'grey3',
                  borderBottomStyle: 'solid',
                }}
              />
            ) : null}
          </React.Fragment>
        )
      })
    : null
}

const Statistic = (props) => {
  const {
    value,
    label,
    displayMultiple,
    statistics,
    context,
    pageColor,
    ...otherProps
  } = props

  const hasStats = statistics && statistics.length > 0

  return (
    <Section>
      <CenterCol>
        <Card expandPadding={true}>
          {context ? (
            <div
              sx={{
                variant: 'text.regular_16',
                color: 'grey1',
                pb: hasStats ? 2 : null,
              }}
            >
              {context}
            </div>
          ) : null}
          {hasStats ? (
            <StatList statistics={statistics} pageColor={pageColor} />
          ) : null}
        </Card>
      </CenterCol>
    </Section>
  )
}

export default Statistic
