/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get } from 'lodash'

import CardHorizontal from '../../card-horizontal'
import Section from '../../section'
import SignpostsSection, { mapSignpost } from '../../signposts'

import {
  renderHTML,
  resolveLink,
  LinkTypes,
  formatImage,
} from '../../../lib/helpers'
import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  VStack,
} from '../../../styles/els'

const Signposts = (props) => {
  const { signposts: _signposts, pageColor, ...otherProps } = props
  const signposts = useMemo(() => {
    return _signposts && _signposts.length > 0
      ? _signposts.map((_signpost, _i) => {
          return mapSignpost(_signpost, { pageColor })
        })
      : null
  }, [_signposts, pageColor])

  return <SignpostsSection signposts={signposts} />
}

export default Signposts
