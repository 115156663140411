/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CallToActionSection from '../../call-to-action'
import { resolveButtons, formatImage } from '../../../lib/helpers'

const CallToAction = (props) => {
  const { image: _image, buttons: _buttons, ...otherProps } = props

  const buttons = resolveButtons(_buttons)
  const image = formatImage(_image)

  return <CallToActionSection {...otherProps} buttons={buttons} image={image} />
}

export default CallToAction
