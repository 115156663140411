/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact } from 'lodash'

import Button from '../../button'
import CardRow from '../../card-row'
import CardSection, {
  MemberStoriesCardSection,
  PastProductionsCardSection,
  ProjectCardSection,
} from '../../card-section'
import CenterCol from '../../center-col'
import Quote from '../../quote'
import Section from '../../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveLink,
} from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const ManualCards = (props) => {
  const {
    heading,
    sectionLinks,
    sectionLink: _sectionLink,
    cards: _cards,
    ...otherProps
  } = props

  const cards = useMemo(() => {
    return _cards && _cards.length > 0
      ? compact(
          _cards.map((_card, _i) => {
            const card = get(_card, 'card.0')

            if (!card) {
              return null
            }

            const title = get(card, 'title')
            const link = get(card, 'uri')
            let image = null
            let buttonLabel = null

            switch (get(card, '__typename')) {
              case 'WpProject':
                image = get(card, 'acfProject.header.image')
                break
              case 'WpVideo':
                image = get(card, 'acfVideo.thumbnail')
                buttonLabel = 'Watch'
                break
            }

            return {
              heading: title,
              image: image ? formatImage(image) : null,
              link: {
                to: link,
              },
              buttonLabel,
            }
          })
        )
      : null
  }, [_cards])

  const sectionLink = sectionLinks ? resolveLink(_sectionLink) : null

  return (
    <CardSection
      heading={heading}
      sectionLink={sectionLink}
      cards={cards}
      {...otherProps}
    />
  )
}

const Cards = (props) => {
  const { heading, cardType, members, relatedPage, ...otherProps } = props

  switch (cardType) {
    case 'members':
      return (
        <MemberStoriesCardSection heading={heading} overwriteCards={members} />
      )
    case 'productions':
      return <PastProductionsCardSection heading={heading} />
    case 'projects':
      return (
        <ProjectCardSection
          heading={heading}
          relatedPage={get(relatedPage, '0.id')}
        />
      )
    default:
      return <ManualCards {...props} />
  }
}

export default Cards
