/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../../center-col'
import Quote from '../../quote'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const QuoteBlock = (props) => {
  const { by, quote, stars, pageColor, ...otherProps } = props

  return (
    <Section>
      <CenterCol>
        <Quote by={by} quote={quote} stars={stars} color={pageColor} />
      </CenterCol>
    </Section>
  )
}

export default QuoteBlock
