/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../../center-col'
import HeadingLink from '../../heading-link'
import ImageFill, { ImageInline } from '../../image'
import Section from '../../section'
import VideoWrapper from '../../video-wrapper'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const VideoBlock = (props) => {
  const { heading, videos, ...otherProps } = props

  return (
    <Section>
      <HeadingLink heading={heading} />
      {!videos || videos.length <= 0 ? null : videos.length === 1 ? (
        <CenterCol span={10}>
          <VideoWrapper {...videos[0]} />
        </CenterCol>
      ) : (
        <FlexCols>
          {videos.map((_video, _i) => {
            return (
              <FlexCol key={_i} base={[1, null, null, 2]}>
                <VideoWrapper {..._video} />
              </FlexCol>
            )
          })}
        </FlexCols>
      )}
    </Section>
  )
}

export default VideoBlock
