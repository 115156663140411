/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import CenterCol from '../../center-col'
import { ImageInline } from '../../image'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const Text = (props) => {
  const { heading, text, ...otherProps } = props

  return (
    <Section>
      <CenterCol>
        <div
          sx={{
            variant: 'textBlock.body',
          }}
        >
          {heading ? <h3>{renderHTML(heading)}</h3> : null}
          {text ? renderHTML(text) : null}
        </div>
      </CenterCol>
    </Section>
  )
}

export default Text
