/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get } from 'lodash'

import CardHorizontal from '../card-horizontal'
import Section from '../section'

import {
  renderHTML,
  resolveLink,
  LinkTypes,
  formatImage,
} from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty, VStack } from '../../styles/els'

export const mapSignpost = (signpost, pageProps) => {
  const {
    related,
    linksTo,
    heading: _heading,
    text: _text,
    buttonLabel: _buttonLabel,
  } = signpost
  const { pageColor } = pageProps

  const targetPage = related
    ? related
    : linksTo === LinkTypes.INTERNAL
    ? get(signpost, 'internalLink.0')
    : null

  const heading = _heading
    ? _heading
    : targetPage
    ? get(targetPage, 'title')
    : null
  const link =
    related && related.uri ? { to: related.uri } : resolveLink(signpost)
  const text = _text ? _text : null
  const buttonLabel = _buttonLabel ? _buttonLabel : null

  const linkedPageColor = targetPage
    ? get(targetPage, 'acfPage.pageColour')
    : null
  const buttonColor = linkedPageColor ? linkedPageColor : pageColor

  const image = targetPage ? get(targetPage, 'acfPage.thumbnail') : null

  return {
    heading,
    text,
    link,
    buttonLabel,
    buttonColor,
    image: image ? formatImage(image) : null,
  }
}

const Signposts = (props) => {
  const { signposts, pageColor, ...otherProps } = props

  return signposts && signposts.length > 0 ? (
    <Section inset={true} bg="grey4">
      <VStack>
        {signposts.map((_signpost, _i) => {
          return <CardHorizontal key={_i} {..._signpost} greyBg={true} />
        })}
      </VStack>
    </Section>
  ) : null
}

export default Signposts
