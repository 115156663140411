/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { ImageInline } from '../../image'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const ImageCol = (props) => {
  const { image: _image, imageRatio: _imageRatio, align } = props

  const image = formatImage(_image)
  const imageRatio = ratioForImage(_imageRatio)

  return (
    <FlexCol across={[12, null, null, align === 'left' ? 5 : 6]} base={12}>
      {image ? <ImageInline image={image} ratio={imageRatio} /> : null}
    </FlexCol>
  )
}

const TextCol = (props) => {
  const { heading, text, align } = props

  return (
    <FlexCol across={[12, null, null, align === 'left' ? 6 : 5]} base={12}>
      <div
        sx={{
          variant: 'textBlock.body',
        }}
      >
        {heading ? <h3>{renderHTML(heading)}</h3> : null}
        {text ? renderHTML(text) : null}
      </div>
    </FlexCol>
  )
}

const TextImage = (props) => {
  const { heading, text, align, ...otherProps } = props

  return (
    <Section>
      <FlexCols
        sx={{
          flexDirection: align === 'right' ? 'row-reverse' : null,
        }}
      >
        <ImageCol {...props} />
        <FlexColEmpty across={1} base={12} />
        <TextCol {...props} />
      </FlexCols>
    </Section>
  )
}

export default TextImage
