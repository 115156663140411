/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../../button'
import Card from '../../card'
import CenterCol from '../../center-col'
import Quote from '../../quote'
import Section from '../../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveLink,
} from '../../../lib/helpers'
import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  VStack,
} from '../../../styles/els'

const Donate = (props) => {
  const { heading, amounts, pageColor, ...otherProps } = props

  return (
    <Section>
      {heading ? (
        <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>{heading}</h3>
      ) : null}
      <FlexCols
        sx={{
          alignItems: 'stretch',
        }}
      >
        {amounts && amounts.length > 0
          ? amounts.map((_amount, _i) => {
              const _link = resolveLink({
                linksTo: 'external',
                externalLink: _amount.link,
              })

              return (
                <FlexCol key={_i} base={[1, null, amounts.length]}>
                  <Card>
                    <VStack sx={{ pb: 8 }}>
                      {_amount.amount ? (
                        <h5
                          sx={{
                            variant: 'text.heading_48',
                            color: pageColor,
                          }}
                        >
                          £{_amount.amount}
                        </h5>
                      ) : null}
                      {_amount.text ? (
                        <div
                          sx={{
                            variant: 'textBlock.body',
                          }}
                        >
                          {renderHTML(_amount.text)}
                        </div>
                      ) : null}
                    </VStack>
                    <Button
                      {..._link}
                      display="primary"
                      label="Donate"
                      color={pageColor}
                    />
                  </Card>
                </FlexCol>
              )
            })
          : null}
      </FlexCols>
    </Section>
  )
}

export default Donate
