/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../../button'
import CenterCol from '../../center-col'
import Quote from '../../quote'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  VStack,
} from '../../../styles/els'

const People = (props) => {
  const { group, ...otherProps } = props

  return (
    <Section>
      <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>Our people</h3>
      <VStack>
        {group.map((_group, _i) => {
          return (
            <FlexCols key={_i}>
              <FlexCol across={[4, null, null, 1]} base={4}>
                <h4 sx={{ variant: 'text.mono' }}>{_group.label}</h4>
              </FlexCol>
              <FlexCol across={[4, null, null, 3]} base={4}>
                {_group.people && _group.people.length > 0 ? (
                  <FlexCols space={5}>
                    {_group.people.map((_person, _i) => {
                      return (
                        <FlexCol key={_i} base={[1, null, 2, 3]}>
                          {_person.name ? (
                            <div sx={{ variant: 'text.bold_20' }}>
                              {_person.name}
                            </div>
                          ) : null}
                          {_person.position ? (
                            <div>{_person.position}</div>
                          ) : null}
                        </FlexCol>
                      )
                    })}
                  </FlexCols>
                ) : null}
              </FlexCol>
            </FlexCols>
          )
        })}
      </VStack>
    </Section>
  )
}

export default People
