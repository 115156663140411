/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction } from 'lodash'
import { graphql } from 'gatsby'

import PageWrapper from '../components/page-wrapper'
import Introduction from '../components/introduction'
import { FullHeader } from '../components/page-header'
import PageBlocks from '../components/page-blocks'

import { Cont, TextBlock, FlexCols, FlexCol, Space } from '../styles/els'
import { useThemeUISetValue } from '../styles/utils'

import { formatImage } from '../lib/helpers'

const Page = (props) => {
  const { data } = props

  const { theme } = useThemeUI()
  const pT = useThemeUISetValue('headingPadding', theme)

  const title = get(data, 'page.title')
  const introduction = get(data, 'page.acfTemplatePage.introText')

  const headerImage = formatImage(get(data, 'page.acfPage.headerImage'))

  const pageContent = get(data, 'page.acfTemplatePage.pageContent')
  const pageColor = get(data, 'page.acfPage.pageColour')

  return (
    <PageWrapper>
      <FullHeader image={headerImage} />
      <Space space={pT} />
      {title || introduction ? (
        <Introduction title={title} introduction={introduction} />
      ) : null}
      {pageContent && pageContent.length > 0 ? (
        <PageBlocks blocks={pageContent} pageColor={pageColor} />
      ) : null}
    </PageWrapper>
  )
}

export default Page

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      template {
        templateName
      }
      acfPage {
        pageColour
        headerImage {
          ...FullsizeImage
        }
      }
      acfTemplatePage {
        introText
        pageContent {
          __typename
          ... on WpPage_Acftemplatepage_PageContent_CallToAction {
            display
            heading
            text
            buttons {
              label
              type
              linksTo
              externalLink
              email
              internalLink {
                __typename
                ... on WpPage {
                  title
                  uri
                  acfPage {
                    pageColour
                  }
                }
              }
            }
            image {
              ...FullsizeImage
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Cards {
            heading
            cardType
            sectionLinks
            sectionLink {
              linksTo
              externalLink
              email
              internalLink {
                ... on WpPage {
                  uri
                }
              }
            }
            cards {
              card {
                __typename
                ... on WpPage {
                  acfPage {
                    thumbnail {
                      ...InlineImage
                    }
                  }
                  title
                  uri
                }
                ... on WpProject {
                  acfProject {
                    header {
                      image {
                        ...InlineImage
                      }
                    }
                  }
                  title
                  uri
                }
                ... on WpVideo {
                  acfVideo {
                    thumbnail {
                      ...InlineImage
                    }
                  }
                  title
                  uri
                }
              }
            }
            members {
              card {
                __typename
                ... on WpStory {
                  title
                  uri
                  id
                  acfStory {
                    thumbnail {
                      ...InlineImage
                    }
                  }
                }
              }
            }
            relatedPage {
              ... on WpPage {
                uri
                id
              }
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Image {
            display
            image {
              ...FullsizeImage
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Quote {
            by
            quote
            stars
          }
          ... on WpPage_Acftemplatepage_PageContent_Text {
            heading
            text
          }
          ... on WpPage_Acftemplatepage_PageContent_TextImage {
            align
            heading
            text
            image {
              ...InlineImage
            }
            imageRatio
          }
          ... on WpPage_Acftemplatepage_PageContent_Signposts {
            signposts {
              linksTo
              externalLink
              email
              internalLink {
                __typename
                ... on WpPage {
                  title
                  uri
                  acfPage {
                    pageColour
                    thumbnail {
                      ...InlineImage
                    }
                  }
                }
              }
              heading
              text
              buttonLabel
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Statistic {
            context
            statistics {
              value
              label
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Bullets {
            heading
            text
            bullets {
              heading
              text
              linksTo
              externalLink
              email
              internalLink {
                __typename
                ... on WpPage {
                  title
                  uri
                  acfPage {
                    pageColour
                    thumbnail {
                      ...InlineImage
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Awards {
            background {
              ...FullsizeImage
            }
            heading
            awards {
              year
              detail
              award
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_People {
            group {
              label
              people {
                name
                position
              }
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Video {
            heading
            videos {
              videoType
              embed
              mux
              label
            }
          }
          ... on WpPage_Acftemplatepage_PageContent_Donate {
            heading
            amounts {
              amount
              text
              link
            }
          }
        }
      }
    }
  }
`
