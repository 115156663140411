/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../../button'
import Card from '../../card'
import CenterCol from '../../center-col'
import Quote from '../../quote'
import Section from '../../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveLink,
} from '../../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../../styles/els'

const Bullets = (props) => {
  const { bullets, text, pageColor, ...otherProps } = props

  return (
    <Section>
      <FlexCols sx={{ pb: 7 }}>
        <FlexCol base={[1, null, null, 2]}>
          <div sx={{ variant: 'textBlock.body' }}>{renderHTML(text)}</div>
        </FlexCol>
      </FlexCols>
      <FlexCols
        sx={{
          alignItems: 'stretch',
        }}
      >
        {bullets && bullets.length > 0
          ? bullets.map((_bullet, _i) => {
              const _link = resolveLink(_bullet)
              return (
                <FlexCol key={_i} base={[1, null, null, 2]}>
                  <Card>
                    {_bullet.heading ? (
                      <div
                        sx={{
                          variant: 'text.heading_36',
                          pb: 5,
                          color: pageColor ? pageColor : 'white',
                        }}
                      >
                        {_bullet.heading}
                      </div>
                    ) : null}
                    <div sx={{ variant: 'textBlock.body', pb: 7 }}>
                      {renderHTML(_bullet.text)}
                    </div>
                    <Button {..._link} display="secondary" />
                  </Card>
                </FlexCol>
              )
            })
          : null}
      </FlexCols>
    </Section>
  )
}

export default Bullets
