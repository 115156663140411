/** @jsx jsx */
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'
import { get, compact } from 'lodash'

import Button from '../button'
import CardRow from '../card-row'
import CenterCol from '../center-col'
import Quote from '../quote'
import Section from '../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveLink,
} from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty } from '../../styles/els'

const HeadingLink = (props) => {
  const { heading, sectionLink, sectionLinkLabel, ...otherProps } = props

  return heading || sectionLink ? (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      {...otherProps}
    >
      {heading ? (
        <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>{heading}</h3>
      ) : null}
      {sectionLink ? (
        <Button
          display="secondary"
          {...sectionLink}
          label={sectionLinkLabel ? sectionLinkLabel : 'Learn more'}
        />
      ) : null}
    </div>
  ) : null
}

export default HeadingLink
