/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Button from '../button'

import { HStack } from '../../styles/els'

const Buttons = (props) => {
  const { buttons, pageColor, ...otherProps } = props

  return buttons && buttons.length > 0 ? (
    <HStack
      space={4}
      sx={{
        alignItems: 'center',
      }}
      {...otherProps}
    >
      {buttons.map((_button, _i) => {
        return <Button key={_i} {..._button} color={pageColor} />
      })}
    </HStack>
  ) : null
}

export default Buttons
