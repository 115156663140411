/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Buttons from '../buttons'
import Card from '../card'
import CenterCol from '../center-col'
import ImageFill, { ImageInline } from '../image'
import Section from '../section'

import {
  renderHTML,
  formatImage,
  ratioForImage,
  resolveButtons,
} from '../../lib/helpers'
import { Cont, FlexCols, FlexCol, FlexColEmpty, VStack } from '../../styles/els'

const Bg = (props) => {
  const { image } = props

  return image ? <ImageFill image={image} /> : null
}

const CallToAction = (props) => {
  const { heading, text, display, buttons, pageColor, ...otherProps } = props

  return (
    <Section
      inset={display === 'block'}
      bg={display === 'block' ? 'grey3' : null}
      bgEl={display === 'block' ? <Bg {...props} /> : null}
    >
      <CenterCol>
        <Card expandPadding={true}>
          {heading || text ? (
            <VStack>
              {heading ? (
                <h4 sx={{ variant: 'text.heading_36', color: pageColor }}>
                  {renderHTML(heading)}
                </h4>
              ) : null}
              {text ? (
                <div sx={{ variant: 'textBlock.body' }}>{renderHTML(text)}</div>
              ) : null}
            </VStack>
          ) : null}
          {buttons && buttons.length > 0 ? (
            <Buttons
              buttons={buttons}
              pageColor={pageColor}
              sx={{ mt: text || heading ? 6 : null }}
            />
          ) : null}
        </Card>
      </CenterCol>
    </Section>
  )
}

export default CallToAction
