/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import Awards from '../../awards'
import Button from '../../button'
import Card from '../../card'
import CenterCol from '../../center-col'
import ImageFill from '../../image'
import Quote from '../../quote'
import Section from '../../section'

import { renderHTML, formatImage, ratioForImage } from '../../../lib/helpers'
import {
  Cont,
  FlexCols,
  FlexCol,
  FlexColEmpty,
  HStack,
} from '../../../styles/els'

const AwardsBlock = (props) => {
  const { awards, background, heading, ...otherProps } = props

  const bgImage = formatImage(background)
  const hasBg = !!bgImage

  return (
    <Section
      inset={hasBg}
      bg={hasBg ? 'grey3' : null}
      bgEl={hasBg ? <ImageFill image={bgImage} /> : null}
    >
      <CenterCol span={8}>
        <Card expandPadding={true}>
          {heading ? (
            <h3 sx={{ variant: 'text.heading_36', pb: 6 }}>{heading}</h3>
          ) : null}
          <Awards awards={awards} />
        </Card>
      </CenterCol>
    </Section>
  )
}

export default AwardsBlock
