/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { css } from '@emotion/react'

import { renderHTML } from '../../lib/helpers'

import { Cont, FlexCols, FlexCol } from '../../styles/els'

import Awards from './blocks/awards'
import Bullets from './blocks/bullets'
import CallToAction from './blocks/call-to-action'
import Cards from './blocks/cards'
import Donate from './blocks/donate'
import Image from './blocks/image'
import People from './blocks/people'
import Quote from './blocks/quote'
import Signposts from './blocks/signposts'
import Statistic from './blocks/statistic'
import Text from './blocks/text'
import TextImage from './blocks/text-image'
import Video from './blocks/video'

const map = {
  WpPage_Acftemplatepage_PageContent_Awards: Awards,
  WpPage_Acftemplatepage_PageContent_Bullets: Bullets,
  WpPage_Acftemplatepage_PageContent_CallToAction: CallToAction,
  WpPage_Acftemplatepage_PageContent_Cards: Cards,
  WpPage_Acftemplatepage_PageContent_Donate: Donate,
  WpPage_Acftemplatepage_PageContent_Image: Image,
  WpPage_Acftemplatepage_PageContent_People: People,
  WpPage_Acftemplatepage_PageContent_Quote: Quote,
  WpPage_Acftemplatepage_PageContent_Signposts: Signposts,
  WpPage_Acftemplatepage_PageContent_Statistic: Statistic,
  WpPage_Acftemplatepage_PageContent_Text: Text,
  WpPage_Acftemplatepage_PageContent_TextImage: TextImage,
  WpPage_Acftemplatepage_PageContent_Video: Video,
}

const PageBlocks = (props) => {
  const { blocks, pageColor, ...otherProps } = props

  return blocks && blocks.length > 0
    ? blocks.map((_block, _i) => {
        const _type = _block.__typename
        const Component = map[_type]
        return Component ? (
          <Component key={_i} pageColor={pageColor} {..._block} />
        ) : null
      })
    : null
}

export default PageBlocks
